import React, { createContext, ReactNode, useContext } from 'react';
import {
    AlertProps,
    AlertsManager,
    createAlertsManager
} from '@bigcommerce/big-design';
import { MessagingType } from '@bigcommerce/big-design/dist/utils';

interface AlertsContextProps {
    addAlert: (variant: MessagingType, message: string) => void;
}

const AlertsContext = createContext<AlertsContextProps | undefined>(undefined);

const alertsManager = createAlertsManager();

export function AlertsProvider({ children }: { children: ReactNode }) {
    const addAlert = (variant: MessagingType, message: string) => {
        alertsManager.clear();
        alertsManager.add({
            messages: [{ text: message }],
            type: variant,
            onClose: () => null,
            autoDismiss: true
        } as AlertProps);
    };

    return (
        <AlertsContext.Provider value={{ addAlert }}>
            <AlertsManager manager={alertsManager} />
            {children}
        </AlertsContext.Provider>
    );
}

export function useAlerts() {
    const context = useContext(AlertsContext);
    if (context === undefined) {
        throw new Error('useAlerts must be used within an AlertsProvider');
    }
    return context;
}
