import React, { ReactElement, useEffect } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { Fade } from '@mui/material';
import './Spinner.css';

interface Props {
    visible: boolean;
    fadeTime?: number;
}

export function Spinner({ visible, fadeTime = 800 }: Props): ReactElement {
    const [show, setShow] = React.useState(visible);

    useEffect(() => {
        if (visible) {
            setShow(true);
        } else {
            setTimeout(() => {
                setShow(visible);
            }, fadeTime);
        }
    }, [visible]);
    return (
        <Fade in={show}>
            <div className={`backdrop cn-spinner-container ${'visible'}`}>
                <CircularProgress />
            </div>
        </Fade>
    );
}
