import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Flex,
    Form,
    FormGroup,
    Input,
    InputProps
} from '@bigcommerce/big-design';
import shopIcon from '../../../assets/icons/shop.svg';
import { ConnectifStore } from '../../../models/ConnectifStore';
import IconTitle from '../../IconTitle/IconTitle';
import './DefaultStoreForm.css';

interface Props {
    defaultConnectifStore: ConnectifStore;
    onChange: (connectifStore: ConnectifStore, hasErrors: boolean) => void;
    disabled: boolean;
    showError?: boolean;
}

export default function DefaultStoreForm({
    defaultConnectifStore,
    onChange,
    disabled,
    showError
}: Props): ReactElement {
    const { t } = useTranslation();
    const errorFieldMessage = t('STORE.INPUTS.REQUIRED_MESSAGE');
    const [isClientIdValid, setIsClientIdValid] = useState<boolean>(true);
    const [isClientSecretValid, setIsClientSecretValid] = useState(true);

    const handleOnChangeClientId: InputProps['onChange'] = event => {
        const clientId = event.target.value;
        if (!clientId.trim()) {
            setIsClientIdValid(false);
        } else {
            setIsClientIdValid(true);
        }

        onChange(
            { ...defaultConnectifStore, clientId },
            isClientIdValid && isClientSecretValid
        );
    };

    const handleOnChangeClientSecret: InputProps['onChange'] = event => {
        const clientSecret = event.target.value;
        if (!clientSecret?.trim()) {
            setIsClientSecretValid(false);
        } else {
            setIsClientSecretValid(true);
        }

        onChange(
            {
                ...defaultConnectifStore,
                clientSecret
            },
            isClientIdValid && isClientSecretValid
        );
    };

    return (
        <>
            <IconTitle
                iconSrc={shopIcon}
                title={t('STORE.YOUR_STORE')}
                description={t('STORE.DESCRIPTION')}
            />
            <Form noValidate>
                <Flex className='cn-max-width-input' flexDirection={'column'}>
                    <FormGroup>
                        <Input
                            label={t('STORE.INPUTS.CLIENT_ID')}
                            onChange={handleOnChangeClientId}
                            type='text'
                            required
                            minLength={1}
                            value={defaultConnectifStore.clientId}
                            error={
                                !isClientIdValid &&
                                showError &&
                                errorFieldMessage
                            }
                            disabled={disabled}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Input
                            label={t('STORE.INPUTS.CLIENT_SECRET')}
                            onChange={handleOnChangeClientSecret}
                            type='text'
                            required
                            minLength={1}
                            value={defaultConnectifStore.clientSecret}
                            error={
                                !isClientSecretValid &&
                                showError &&
                                errorFieldMessage
                            }
                            disabled={disabled}
                        />
                    </FormGroup>
                </Flex>
            </Form>
        </>
    );
}
