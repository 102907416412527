export class BadRequestError extends Error {
    errorCode: string;
    clientId?: string;
    currencyCode?: string;

    constructor(
        message: string,
        errorCode: string,
        clientId?: string,
        currencyCode?: string
    ) {
        super(message);
        this.errorCode = errorCode;
        this.clientId = clientId;
        this.currencyCode = currencyCode;
    }
}
