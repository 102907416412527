import { TFunction } from 'react-i18next';

export enum ErrorCode {
    E0101 = 'E0101',
    E0201 = 'E0201',
    E0202 = 'E0202',
    E0203 = 'E0203',
    E0204 = 'E0204',
    E0205 = 'E0205'
}

const errorMessages: Record<ErrorCode, string> = {
    [ErrorCode.E0101]: 'ERROR.E0101',
    [ErrorCode.E0201]: 'ERROR.E0201',
    [ErrorCode.E0202]: 'ERROR.E0202',
    [ErrorCode.E0203]: 'ERROR.E0203',
    [ErrorCode.E0204]: 'ERROR.E0204',
    [ErrorCode.E0205]: 'ERROR.E0205'
};

export function getErrorMessage(
    errorCode: ErrorCode,
    t: TFunction<'translation', undefined>
): string {
    const errorTranslationKey = errorMessages[errorCode];
    return errorTranslationKey
        ? t(errorTranslationKey)
        : t('MESSAGE.GENERAL_ERROR');
}
