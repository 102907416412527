import React, { ReactElement } from 'react';
import { Component } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import logo from '../../resources/img/logo-with-text-right.svg';
import './unauthorized.css';

interface Props {}

interface State {}

class Unauthorized extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
    }

    render(): ReactElement {
        return (
            <div className={'login-bg-container'}>
                <div className={'login-container'}>
                    <Container component='main' maxWidth='xs'>
                        <CssBaseline />
                        <Card>
                            <CardContent>
                                <div className='logo-container'>
                                    <img
                                        src={logo}
                                        className='App-logo'
                                        alt='logo'
                                    />
                                </div>
                                <div className='logo-title'>
                                    <Typography component='h1' variant='h5'>
                                        Forbidden.
                                    </Typography>
                                </div>
                            </CardContent>
                        </Card>
                    </Container>
                </div>
            </div>
        );
    }
}
export default Unauthorized;
