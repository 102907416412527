import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from '../../resources/img/logo-with-text-right.svg';
import './auth.css';
import AuthService from '../../services/AuthService';
import { Spinner } from '../../components/Spinner';
import { useAlerts } from '../../state/AlertsContext';
import { useTranslation } from 'react-i18next';

interface AuthPageState {
    code: string;
    scope: string;
    context: string;
    bigcommerceAccountUuId: string;
    authComplete: boolean;
    signedPayload?: string;
    isLoading: boolean;
}

const AuthPage: React.FC = () => {
    const { t } = useTranslation();
    const queryParameters = new URLSearchParams(location.search);
    const [state, setState] = useState<AuthPageState>({
        code: queryParameters.get('code') || '',
        scope: queryParameters.get('scope') || '',
        context: queryParameters.get('context') || '',
        bigcommerceAccountUuId: queryParameters.get('account_uuid') || '',
        authComplete: false,
        isLoading: true
    });

    const { addAlert } = useAlerts();
    const navigate = useNavigate();

    const handleAuth = async () => {
        const authResult = await AuthService.auth(
            state.code,
            state.scope,
            state.context,
            state.bigcommerceAccountUuId
        );

        if (authResult.success && authResult.authSignedPayload) {
            setState(prevState => ({
                ...prevState,
                authComplete: true,
                signedPayload: authResult.authSignedPayload,
                isLoading: false
            }));
            addAlert('success', t('MESSAGE.APP_INSTALLED'));
            navigate(`/load?signed_payload=${authResult.authSignedPayload}`);
        } else {
            setState(prevState => ({
                ...prevState,
                isLoading: false
            }));
            addAlert('error', t('MESSAGE.APP_INSTALLING_ERROR'));
        }
    };

    useEffect(() => {
        handleAuth();
    }, []);

    return (
        <>
            <Spinner visible={state.isLoading} />
            {!state.authComplete && (
                <div className='App-header'>
                    <img src={logo} className='App-logo' alt='logo' />
                    <p>{t('INSTALLING_APP')}</p>
                </div>
            )}
        </>
    );
};

export default AuthPage;
