import React, { ReactElement, useState, useEffect } from 'react';
import { ConnectifStore } from '../../../models/ConnectifStore';
import {
    AccordionPanel,
    Box,
    Button,
    Flex,
    FlexItem,
    Tooltip
} from '@bigcommerce/big-design';
import IconTitle from '../../IconTitle/IconTitle';
import shopIcon from '../../../assets/icons/shop.svg';
import addIcon from '../../../assets/icons/add.svg';
import noStoresImage from '../../../assets/images/no-stores.png';
import { AccordionProps } from '@bigcommerce/big-design/dist/components/AccordionPanel/Accordion';
import { useTranslation } from 'react-i18next';
import './MultiStoreSettings.css';
import { CircularProgress } from '@mui/material';
import { useAlerts } from '../../../state/AlertsContext';
import { useSignedPayload } from '../../../state/SignedPayloadContext';
import StoreForm from './StoreForm';
import { ConnectifStorePayload } from '../../../models/ConnectifStorePayload';
import { StoreError } from '../../../models/AppSettingsResponse';
import MultiStoreService from '../../../services/MultiStoreService';
import { AppSettings } from '../../../models/AppSettings';

interface Props {
    appSettings: AppSettings;
    multiStoreErrors: StoreError[];
}

export default function MultiStoreSettings({
    multiStoreErrors,
    appSettings
}: Props): ReactElement {
    const { t } = useTranslation();
    const [isAddStoreAllowed, setIsAddStoreAllowed] = useState(true);
    const [isLoading, setIsLoading] = useState(true);

    const [connectifStores, setConnectifStores] = useState<ConnectifStore[]>(
        []
    );
    const [accordionItems, setAccordionItems] = useState<AccordionProps[]>([]);
    const { addAlert } = useAlerts();
    const { signedPayload } = useSignedPayload();

    useEffect(() => {
        loadData();
    }, [signedPayload]);

    const loadData = async () => {
        setIsLoading(true);
        await loadConnectifStores();
        setIsLoading(false);
    };

    const loadConnectifStores = async () => {
        const result = await MultiStoreService.getStores(signedPayload);
        if (!result.success) {
            addAlert('error', t('MESSAGE.GENERAL_ERROR'));
            return;
        }
        setConnectifStores(result.connectifStores);
    };

    useEffect(() => {
        const items: AccordionProps[] = connectifStores.map(
            (connectifStore, index) => {
                const hasError = multiStoreErrors.some(
                    store =>
                        store.clientId === connectifStore.clientId &&
                        store.currencyCode === connectifStore.currencyCode
                );
                return createStoreFormItem(connectifStore, index, hasError);
            }
        );
        setAccordionItems(items);
    }, [connectifStores, multiStoreErrors]);

    const createStoreFormItem = (
        connectifStore: ConnectifStore,
        index: number,
        hasError = false
    ) => {
        let textHeader = `Store ${index + 1}`;

        if (connectifStore.clientId) {
            textHeader += `: ID ${connectifStore.clientId}`;
        }

        if (connectifStore.currencyCode) {
            textHeader += ` - ${connectifStore.currencyCode}`;
        }

        let allowEdit = !connectifStore.id;
        if (
            connectifStore.clientId === appSettings.clientId &&
            connectifStore.clientSecret === appSettings.clientSecret &&
            !connectifStore.currencyCode
        ) {
            allowEdit = true;
        }

        if (allowEdit) {
            setIsAddStoreAllowed(false);
        }

        return {
            header: textHeader,
            children: (
                <StoreForm
                    connectifStore={connectifStore}
                    allowEdit={allowEdit}
                    hasError={hasError}
                    onSave={saveConnectifStore}
                    onRemove={removeConnectifStore}
                    onCancel={cancelAddConnectifStore}
                />
            ),
            isExpanded: allowEdit || hasError,
            onClick: () => handleAccordionClick(index)
        };
    };

    const saveConnectifStore = async (): Promise<void> => {
        try {
            setIsAddStoreAllowed(true);
            addAlert('success', t('MESSAGE.STORE_SAVED'));
        } catch (error) {
            addAlert('error', t('MESSAGE.CANNOT_ADD_STORE'));
        }
        await loadData();
    };

    const removeConnectifStore = async (
        connectifStoreId: string
    ): Promise<void> => {
        try {
            const updatedConnectifStores = connectifStores.filter(
                store => store.clientId !== connectifStoreId
            );
            setConnectifStores(updatedConnectifStores);
            addAlert('success', t('MESSAGE.STORE_REMOVED'));
        } catch (error) {
            addAlert('error', t('MESSAGE.CANNOT_REMOVE_STORE'));
        }
        await loadData();
    };

    const cancelAddConnectifStore = () => {
        if (!connectifStores.length) {
            return;
        }
        setConnectifStores(connectifStores.slice(0, -1));
        setIsAddStoreAllowed(true);
    };

    const handleAccordionClick = (index: number) => {
        setAccordionItems(prevItems =>
            prevItems.map((item, i) => ({
                ...item,
                isExpanded: i === index ? !item.isExpanded : false
            }))
        );
    };

    const renderAddStoreButton = () => {
        if (!isAddStoreAllowed) {
            return (
                <Tooltip
                    placement='bottom'
                    trigger={
                        <Box>
                            <Button
                                onClick={handleAddStore}
                                variant='primary'
                                disabled={true}
                                iconLeft={
                                    <img width={14} height={14} src={addIcon} />
                                }
                            >
                                {t('STORE.MULTISTORE.ADD_STORE')}
                            </Button>
                        </Box>
                    }
                >
                    {t('STORE.MULTISTORE.ADD_STORE_TOOLTIP')}
                </Tooltip>
            );
        }

        return (
            <Button
                onClick={handleAddStore}
                variant='primary'
                disabled={isLoading}
                iconLeft={<img width={14} height={14} src={addIcon} />}
            >
                {t('STORE.MULTISTORE.ADD_STORE')}
            </Button>
        );
    };

    const handleAddStore = () => {
        setIsAddStoreAllowed(false);
        const emptyConnectifStore: ConnectifStorePayload = {
            clientId: '',
            clientSecret: '',
            currencyCode: ''
        };
        setConnectifStores(connectifStores.concat(emptyConnectifStore));
    };

    return (
        <>
            <Flex justifyContent={'space-between'} flexGap={'40px'}>
                <FlexItem>
                    <IconTitle
                        iconSrc={shopIcon}
                        title={t('STORE.MULTISTORE.YOUR_STORE')}
                        description={t('STORE.MULTISTORE.DESCRIPTION')}
                    />
                </FlexItem>
                <FlexItem className='cn-add-store-button'>
                    {renderAddStoreButton()}
                </FlexItem>
            </Flex>
            {isLoading ? (
                <Flex marginTop={'xxLarge'} justifyContent={'center'}>
                    <CircularProgress size={40} />
                </Flex>
            ) : accordionItems.length > 0 ? (
                <div
                    style={{ position: 'relative' }}
                    className='cn-hide-accordion-header'
                >
                    <AccordionPanel header='' panels={accordionItems} />
                </div>
            ) : (
                <Flex
                    marginTop={'xxLarge'}
                    className='cn-no-stores-content'
                    flexDirection={'column'}
                    flexGap={'30px'}
                    alignContent={'center'}
                >
                    <FlexItem>
                        <img width={120} height={120} src={noStoresImage} />
                    </FlexItem>
                    <FlexItem>{t('STORE.MULTISTORE.NO_STORES')}</FlexItem>
                </Flex>
            )}
        </>
    );
}
