import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import en from './translations/en.json';
import es from './translations/es.json';

const resources = {
    en,
    es
};

async function initTranslations(userLocale: string): Promise<void> {
    await i18n
        .use(initReactI18next) // passes i18n down to react-i18next
        .init({
            fallbackLng: 'en',
            resources,
            lng: userLocale,
            interpolation: {
                escapeValue: false // react already safes from xss
            }
        });
}

export default initTranslations;
