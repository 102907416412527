import React, { ReactElement } from 'react';
import { Flex, FlexItem, Text } from '@bigcommerce/big-design';
interface Props {
    iconSrc: string;
    title: string;
    description: string;
}
export default function IconTitle(props: Props): ReactElement {
    return (
        <>
            <Flex flexDirection={'row'} flexGap={'10px'}>
                <FlexItem>
                    <img src={props.iconSrc} alt={props.title} />
                </FlexItem>
                <FlexItem className='cn-option-title'>
                    <span>{props.title}</span>
                </FlexItem>
            </Flex>
            <Text marginTop={'xSmall'}>{props.description}</Text>
        </>
    );
}
