import React from 'react';
import ReactDOM from 'react-dom/client';
import './Index.css';
import App from './App';
import Theme from './Theme';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { ThemeProvider } from '@mui/material';
import { GlobalStyles } from '@bigcommerce/big-design';
import { AlertsProvider } from './state/AlertsContext';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <ThemeProvider theme={Theme}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <AlertsProvider>
                <App>
                    <GlobalStyles />
                </App>
            </AlertsProvider>
        </LocalizationProvider>
    </ThemeProvider>
);
