import { API_BASE_URL } from '../Config';
import { BadRequestError } from '../models/errors/BadRequestError';
import { NotFoundError } from '../models/errors/NotFoundError';
import { UnauthorizedError } from '../models/errors/UnauthorizedError';
import { DuplicateKeyError } from '../models/errors/DuplicateKeyError';

type ParamType = string | boolean | number | Date;

type IRequestService = {
    cnFetch(
        path: string,
        requestOptions?: RequestOptions,
        queryParams?: Record<string, undefined | ParamType | ParamType[]>
    ): Promise<Response>;
};

type RequestOptions = {
    headers?: Record<string, string>;
    method: string;
    body?: string;
    bigcommerceSignature?: string;
};

function objToQueryString(
    obj: Record<string, undefined | ParamType | ParamType[]>
): string {
    const hasValue = (
        kv: [string, undefined | ParamType | ParamType[]]
    ): kv is [string, ParamType | ParamType[]] =>
        kv[1] !== undefined && kv[1] !== null;
    const getParam = (key: string, value: ParamType) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(value.toString())}`;
    return Object.entries(obj)
        .filter(hasValue)
        .flatMap(([key, value]) =>
            Array.isArray(value)
                ? value.map(v => getParam(key, v))
                : getParam(key, value)
        )
        .join('&');
}

const RequestService: IRequestService = {
    cnFetch(
        path: string,
        requestOptions?: RequestOptions,
        queryParams?: Record<string, undefined | ParamType | ParamType[]>
    ): Promise<Response> {
        const { headers, method, body, bigcommerceSignature } =
            requestOptions ?? {
                headers: {},
                method: 'GET'
            };
        const requestInit: RequestInit = {
            mode: 'cors',
            headers: new Headers({
                'Content-Type': 'application/json',
                ...(bigcommerceSignature
                    ? { 'cn-bigcommerce-signature': bigcommerceSignature }
                    : {}),
                ...headers
            }),
            method,
            body
        };

        let url = API_BASE_URL + path;
        if (queryParams) {
            const queryParamsString = objToQueryString(queryParams);
            if (queryParamsString) {
                url = url + '?' + queryParamsString;
            }
        }

        return fetch(url, requestInit).then(async response => {
            // Unauthorized
            if (response.status === 401) {
                window.location.replace('/unauthorized');
            }
            if (response.ok) {
                return Promise.resolve(response);
            } else {
                if (response.status === 400) {
                    const result = await response.json();
                    throw new BadRequestError(
                        result.error.message,
                        result.error.errorCode,
                        result.error.clientId,
                        result.error.currencyCode
                    );
                }
                if (response.status === 403) {
                    throw new UnauthorizedError();
                }
                if (response.status === 404) {
                    throw new NotFoundError();
                }
                if (response.status === 409) {
                    throw new DuplicateKeyError();
                }
                throw Error(response.statusText);
            }
        });
    }
};
export default RequestService;
