import { BadRequestError } from '../models/errors/BadRequestError';
import RequestService from './RequestService';

const AuthService = {
    async auth(
        code: string,
        scope: string,
        context: string,
        bigcommerceAccountUuId: string
    ): Promise<{ success: boolean; authSignedPayload?: string }> {
        try {
            const result = await RequestService.cnFetch('/api/auth', {
                method: 'POST',
                body: JSON.stringify({
                    code,
                    scope,
                    context,
                    bigcommerceAccountUuId
                })
            });
            const body: { authSignedPayload: string } = await result.json();

            return { success: true, authSignedPayload: body.authSignedPayload };
        } catch (error) {
            if (error instanceof BadRequestError) {
                return { success: false };
            }
            throw new Error('Error while fetching access token');
        }
    }
};
export default AuthService;
