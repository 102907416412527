import React, { ReactElement, useEffect, useState } from 'react';
import './CatalogSettings.css';
import { useTranslation } from 'react-i18next';
import {
    Badge,
    Box,
    Flex,
    FlexItem,
    InlineMessage,
    InputProps,
    MultiSelect,
    PillTabs,
    SelectOption
} from '@bigcommerce/big-design';
import Radio from '../Radio/Radio';
import ImagePropertiesConfig from '../ImagePropertiesConfig/ImagePropertiesConfig';
import { AppSettings } from '../../models/AppSettings';
import { ImageSettings } from '../../models/ImageSettings';
import { ScriptConsentCategory } from '../../enums/ScriptConsentCategory';
import ConnectifStoreService from '../../services/ConnectifStoreService';
import { useSignedPayload } from '../../state/SignedPayloadContext';

enum TabsEnum {
    PRODUCTS = 'products',
    PURCHASES = 'purchases',
    DATA = 'data'
}

interface Props {
    appSettings: AppSettings;
    onChangeAppSettings: (appSettings: AppSettings) => void;
}

export function CatalogSettings({
    appSettings,
    onChangeAppSettings
}: Props): ReactElement {
    const { t } = useTranslation();
    const [activeTab, setActiveTab] = useState<TabsEnum>(TabsEnum.PRODUCTS);
    const [productImageProperties, setProductImageProperties] =
        useState<ImageSettings>(
            appSettings.productImageProperties || { width: 640, height: 640 }
        );
    const [orderStatuses, setOrderStatuses] = useState<SelectOption<number>[]>(
        []
    );
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const { signedPayload } = useSignedPayload();

    const [
        productThumbnailImageProperties,
        setProductThumbnailImageProperties
    ] = useState<ImageSettings>(
        appSettings.productThumbnailImageProperties || {
            width: 200,
            height: 200
        }
    );

    useEffect(() => {
        setIsLoading(true);
        loadOrderStatuses();
        setIsLoading(false);
    }, []);

    useEffect(() => {
        if (appSettings.productImageProperties) {
            setProductImageProperties(appSettings.productImageProperties);
        }
        if (appSettings.productThumbnailImageProperties) {
            setProductThumbnailImageProperties(
                appSettings.productThumbnailImageProperties
            );
        }
    }, [
        appSettings.productImageProperties,
        appSettings.productThumbnailImageProperties
    ]);

    const loadOrderStatuses = async () => {
        const resultOrderStatuses =
            await ConnectifStoreService.getOrderStatuses(signedPayload);
        if (resultOrderStatuses) {
            const orderStatusesOptions = resultOrderStatuses.map(
                orderStatus => {
                    const customName =
                        orderStatus.system_label !== orderStatus.custom_label
                            ? `(${orderStatus.custom_label})`
                            : '';
                    const fullName = `${orderStatus.system_label} ${customName}`;
                    return {
                        value: orderStatus.id,
                        content: fullName
                    };
                }
            );
            setOrderStatuses(orderStatusesOptions);
        }
    };

    const tabs = [
        {
            id: TabsEnum.PRODUCTS,
            title: t('CONFIGURATION.TABS.PRODUCTS.NAME')
        },
        {
            id: TabsEnum.PURCHASES,
            title: t('CONFIGURATION.TABS.PURCHASES.NAME')
        },
        {
            id: TabsEnum.DATA,
            title: t('CONFIGURATION.TABS.DATA.NAME')
        }
    ];

    const onChangeUseSkuAsProductId: InputProps['onChange'] = event =>
        onChangeAppSettings({
            ...appSettings,
            useSkuAsProductId: event.target.value === 'sku' ? true : false
        });

    const handleOfflineShoppingChange: InputProps['onChange'] = event =>
        onChangeAppSettings({
            ...appSettings,
            offlineShopping: event.target.value === 'offline' ? true : false
        });

    const handleSelectOrderStatusesChange = (value: number[]) => {
        onChangeAppSettings({
            ...appSettings,
            offlineShoppingOrderStatuses: value
        });
    };

    const handleProductImageSettingsChange = (imageSettings: ImageSettings) => {
        setProductImageProperties(imageSettings);
        onChangeAppSettings({
            ...appSettings,
            productImageProperties: imageSettings
        });
    };

    const handleProductThumbnailImageSettingsChange = (
        imageSettings: ImageSettings
    ) => {
        setProductThumbnailImageProperties(imageSettings);
        onChangeAppSettings({
            ...appSettings,
            productThumbnailImageProperties: imageSettings
        });
    };

    const handleScriptConsentCategoryChange: InputProps['onChange'] = event => {
        onChangeAppSettings({
            ...appSettings,
            scriptConsentCategory: event.target.value as ScriptConsentCategory
        });
    };

    const onTabClick = (tabId: string) => {
        setActiveTab(tabId as TabsEnum);
    };

    return (
        <Box
            marginTop={'large'}
            paddingBottom={'xLarge'}
            border='box'
            borderRadius='normal'
        >
            <Flex
                padding={'large'}
                className='cn-section-header'
                alignItems={'center'}
                flexGap={'20px'}
            >
                <FlexItem className='cn-section-title'>
                    <span>{t('CONFIGURATION.TITLE')}</span>
                </FlexItem>
                <FlexItem>
                    {appSettings.offlineShopping &&
                        appSettings.offlineShoppingOrderStatuses?.length ===
                            0 &&
                        activeTab !== TabsEnum.PURCHASES && (
                            <Badge
                                label={t('MESSAGE.OFFLINE_PURCHASES_ERROR')}
                                variant='danger'
                            />
                        )}
                </FlexItem>
            </Flex>
            <Flex padding={'large'} flexDirection={'column'} flexGap={'30px'}>
                <FlexItem>
                    <Flex flexDirection={'column'} flexGap={'30px'}>
                        <FlexItem>
                            <PillTabs
                                activePills={[activeTab]}
                                items={tabs}
                                onPillClick={onTabClick}
                            />
                        </FlexItem>
                        <FlexItem>
                            {activeTab === 'products' && (
                                <Flex flexDirection={'column'} flexGap={'20px'}>
                                    <FlexItem>
                                        <span>
                                            {t(
                                                'CONFIGURATION.TABS.PRODUCTS.DESCRIPTION'
                                            )}
                                        </span>
                                    </FlexItem>
                                    <FlexItem>
                                        <Flex
                                            flexDirection={'column'}
                                            flexGap={'7px'}
                                        >
                                            <FlexItem>
                                                <Radio
                                                    checked={
                                                        !appSettings.useSkuAsProductId
                                                    }
                                                    label={t(
                                                        'CONFIGURATION.TABS.PRODUCTS.RADIO_OPTIONS.PRODUCT_ID'
                                                    )}
                                                    onChange={
                                                        onChangeUseSkuAsProductId
                                                    }
                                                    value='productId'
                                                />
                                            </FlexItem>
                                            <FlexItem>
                                                <Radio
                                                    checked={
                                                        appSettings.useSkuAsProductId
                                                    }
                                                    label={t(
                                                        'CONFIGURATION.TABS.PRODUCTS.RADIO_OPTIONS.PRODUCT_SKU'
                                                    )}
                                                    onChange={
                                                        onChangeUseSkuAsProductId
                                                    }
                                                    value='sku'
                                                />
                                            </FlexItem>
                                        </Flex>
                                    </FlexItem>
                                    <FlexItem marginTop={'xSmall'}>
                                        <ImagePropertiesConfig
                                            title={t(
                                                'CONFIGURATION.TABS.PRODUCTS.IMAGES.PRODUCT.TITLE'
                                            )}
                                            description={t(
                                                'CONFIGURATION.TABS.PRODUCTS.IMAGES.PRODUCT.DESCRIPTION'
                                            )}
                                            imageSettings={
                                                productImageProperties
                                            }
                                            onChange={
                                                handleProductImageSettingsChange
                                            }
                                        />
                                    </FlexItem>
                                    <FlexItem>
                                        <ImagePropertiesConfig
                                            title={t(
                                                'CONFIGURATION.TABS.PRODUCTS.IMAGES.THUMBNAIL.TITLE'
                                            )}
                                            description={t(
                                                'CONFIGURATION.TABS.PRODUCTS.IMAGES.THUMBNAIL.DESCRIPTION'
                                            )}
                                            imageSettings={
                                                productThumbnailImageProperties
                                            }
                                            onChange={
                                                handleProductThumbnailImageSettingsChange
                                            }
                                        />
                                    </FlexItem>
                                </Flex>
                            )}
                            {activeTab === 'purchases' && (
                                <Flex flexDirection={'column'} flexGap={'20px'}>
                                    <FlexItem>
                                        <span>
                                            {t(
                                                'CONFIGURATION.TABS.PURCHASES.DESCRIPTION'
                                            )}
                                        </span>
                                    </FlexItem>
                                    <FlexItem>
                                        <Radio
                                            checked={
                                                !appSettings.offlineShopping
                                            }
                                            label={t(
                                                'CONFIGURATION.TABS.PURCHASES.RADIO_OPTIONS.ONLINE_PURCHASES'
                                            )}
                                            onChange={
                                                handleOfflineShoppingChange
                                            }
                                            value='online'
                                            description={t(
                                                'CONFIGURATION.TABS.PURCHASES.RADIO_OPTIONS.ONLINE_DESCRIPTION'
                                            )}
                                            disabled={
                                                appSettings.trackingEnabled
                                            }
                                            tooltipMessage={
                                                appSettings.trackingEnabled
                                                    ? t(
                                                          'STORE.MULTISTORE.TOOLTIP_MESSAGE'
                                                      )
                                                    : undefined
                                            }
                                        />
                                    </FlexItem>
                                    <FlexItem>
                                        <Radio
                                            checked={
                                                !!appSettings.offlineShopping
                                            }
                                            label={t(
                                                'CONFIGURATION.TABS.PURCHASES.RADIO_OPTIONS.OFFLINE_PURCHASES'
                                            )}
                                            onChange={
                                                handleOfflineShoppingChange
                                            }
                                            value='offline'
                                            description={t(
                                                'CONFIGURATION.TABS.PURCHASES.RADIO_OPTIONS.OFFLINE_DESCRIPTION'
                                            )}
                                            disabled={
                                                appSettings.trackingEnabled
                                            }
                                            tooltipMessage={
                                                appSettings.trackingEnabled
                                                    ? t(
                                                          'STORE.MULTISTORE.TOOLTIP_MESSAGE'
                                                      )
                                                    : undefined
                                            }
                                        />
                                    </FlexItem>
                                    <FlexItem>
                                        <Flex
                                            flexDirection={'column'}
                                            flexGap={'10px'}
                                        >
                                            <FlexItem className='cn-ml-as-radio-option'>
                                                <span className='cn-option-title'>
                                                    {t(
                                                        'CONFIGURATION.TABS.PURCHASES.OFFLINE_STATUSES.TITLE'
                                                    )}
                                                </span>
                                            </FlexItem>
                                            <FlexItem className='cn-ml-as-radio-option'>
                                                <span className='cn-option-description'>
                                                    {t(
                                                        'CONFIGURATION.TABS.PURCHASES.OFFLINE_STATUSES.DESCRIPTION'
                                                    )}
                                                </span>
                                            </FlexItem>
                                            <FlexItem className='cn-ml-as-radio-option cn-multiselect-container'>
                                                <MultiSelect
                                                    multiple={true}
                                                    filterable={true}
                                                    maxHeight={300}
                                                    onOptionsChange={
                                                        handleSelectOrderStatusesChange
                                                    }
                                                    value={
                                                        appSettings.offlineShoppingOrderStatuses
                                                    }
                                                    options={orderStatuses}
                                                    placeholder={
                                                        !appSettings
                                                            .offlineShoppingOrderStatuses
                                                            ?.length
                                                            ? t(
                                                                  'CONFIGURATION.TABS.PURCHASES.OFFLINE_STATUSES.SELECT_PLACEHOLDER'
                                                              )
                                                            : undefined
                                                    }
                                                    placement='bottom-start'
                                                    disabled={
                                                        isLoading ||
                                                        !appSettings.offlineShopping
                                                    }
                                                />
                                                {appSettings.offlineShopping &&
                                                    appSettings
                                                        .offlineShoppingOrderStatuses
                                                        ?.length === 0 && (
                                                        <InlineMessage
                                                            marginVertical='medium'
                                                            messages={[
                                                                {
                                                                    text: t(
                                                                        'CONFIGURATION.TABS.PURCHASES.OFFLINE_STATUSES.MIN_SELECT_ONE_MESSAGE'
                                                                    )
                                                                }
                                                            ]}
                                                            type='error'
                                                        />
                                                    )}
                                            </FlexItem>
                                        </Flex>
                                    </FlexItem>
                                </Flex>
                            )}
                            {activeTab === 'data' && (
                                <Flex flexDirection={'column'} flexGap={'20px'}>
                                    <FlexItem>
                                        <span>
                                            {t(
                                                'CONFIGURATION.TABS.DATA.CONSENT_CATEGORY.DESCRIPTION'
                                            )}
                                        </span>
                                    </FlexItem>
                                    <FlexItem>
                                        <Radio
                                            checked={
                                                appSettings.scriptConsentCategory ===
                                                ScriptConsentCategory.Essential
                                            }
                                            label={t(
                                                'CONFIGURATION.TABS.DATA.CONSENT_CATEGORY.RADIO_OPTIONS.ESSENTIAL'
                                            )}
                                            onChange={
                                                handleScriptConsentCategoryChange
                                            }
                                            value={
                                                ScriptConsentCategory.Essential
                                            }
                                            description={t(
                                                'CONFIGURATION.TABS.DATA.CONSENT_CATEGORY.RADIO_OPTIONS.ESSENTIAL_DESCRIPTION'
                                            )}
                                        />
                                    </FlexItem>
                                    <FlexItem>
                                        <Radio
                                            checked={
                                                appSettings.scriptConsentCategory ===
                                                ScriptConsentCategory.Analytics
                                            }
                                            label={t(
                                                'CONFIGURATION.TABS.DATA.CONSENT_CATEGORY.RADIO_OPTIONS.ANALYTICS'
                                            )}
                                            onChange={
                                                handleScriptConsentCategoryChange
                                            }
                                            value={
                                                ScriptConsentCategory.Analytics
                                            }
                                            description={t(
                                                'CONFIGURATION.TABS.DATA.CONSENT_CATEGORY.RADIO_OPTIONS.ANALYTICS_DESCRIPTION'
                                            )}
                                        />
                                    </FlexItem>
                                    <FlexItem>
                                        <Radio
                                            checked={
                                                appSettings.scriptConsentCategory ===
                                                ScriptConsentCategory.Functional
                                            }
                                            label={t(
                                                'CONFIGURATION.TABS.DATA.CONSENT_CATEGORY.RADIO_OPTIONS.FUNCTIONAL'
                                            )}
                                            onChange={
                                                handleScriptConsentCategoryChange
                                            }
                                            value={
                                                ScriptConsentCategory.Functional
                                            }
                                            description={t(
                                                'CONFIGURATION.TABS.DATA.CONSENT_CATEGORY.RADIO_OPTIONS.FUNCTIONAL_DESCRIPTION'
                                            )}
                                        />
                                    </FlexItem>
                                    <FlexItem>
                                        <Radio
                                            checked={
                                                appSettings.scriptConsentCategory ===
                                                ScriptConsentCategory.Targeting
                                            }
                                            label={t(
                                                'CONFIGURATION.TABS.DATA.CONSENT_CATEGORY.RADIO_OPTIONS.TARGETING'
                                            )}
                                            onChange={
                                                handleScriptConsentCategoryChange
                                            }
                                            value={
                                                ScriptConsentCategory.Targeting
                                            }
                                            description={t(
                                                'CONFIGURATION.TABS.DATA.CONSENT_CATEGORY.RADIO_OPTIONS.TARGETING_DESCRIPTION'
                                            )}
                                        />
                                    </FlexItem>
                                </Flex>
                            )}
                        </FlexItem>
                    </Flex>
                </FlexItem>
            </Flex>
        </Box>
    );
}
