import React, { ReactElement } from 'react';
import {
    Box,
    Flex,
    Radio as NativeRadio,
    RadioProps,
    Tooltip
} from '@bigcommerce/big-design';

interface Props extends RadioProps {
    description?: string;
    tooltipMessage?: string;
}

export default function Radio(props: Props): ReactElement {
    const renderNativeRadio = () => (
        <NativeRadio
            checked={props.checked}
            label={props.label}
            onChange={props.onChange}
            value={props.value}
            disabled={props.disabled}
        />
    );

    return (
        <>
            {props.tooltipMessage ? (
                <Tooltip
                    placement='bottom'
                    trigger={<Box>{renderNativeRadio()}</Box>}
                >
                    {props.tooltipMessage}
                </Tooltip>
            ) : (
                renderNativeRadio()
            )}

            {props.description && (
                <Flex
                    className='cn-option-description cn-ml-as-radio-option'
                    marginTop={'xxSmall'}
                >
                    <span>{props.description}</span>
                </Flex>
            )}
        </>
    );
}
