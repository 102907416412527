import {
    AddConnectifStoreErrorResponse,
    AddConnectifStoreResponse
} from '../models/AddConnectifStoreResponse';
import { ConnectifStoresResponse } from '../models/ConnectifStoresResponse';
import RequestService from './RequestService';

type AddMultiStorePayload = {
    clientId?: string;
    clientSecret?: string;
    currencyCode?: string;
};

type IMultiStoreService = {
    getStores(signed_payload: string): Promise<ConnectifStoresResponse>;
    addStore(
        signed_payload: string,
        multiStore: AddMultiStorePayload
    ): Promise<AddConnectifStoreResponse>;
    deleteStore(signed_payload: string, storeId: string): Promise<boolean>;
};

const MultiStoreService: IMultiStoreService = {
    async getStores(signed_payload: string): Promise<ConnectifStoresResponse> {
        try {
            const result = await RequestService.cnFetch(
                '/api/app/multi-store/stores',
                {
                    method: 'GET',
                    bigcommerceSignature: signed_payload
                }
            );

            return (await result.json()) as ConnectifStoresResponse;
        } catch (error) {
            return {
                success: false
            };
        }
    },

    async addStore(
        signed_payload: string,
        multiStore: AddMultiStorePayload
    ): Promise<AddConnectifStoreResponse> {
        try {
            const result = await RequestService.cnFetch(
                '/api/app/multi-store/add',
                {
                    method: 'POST',
                    bigcommerceSignature: signed_payload,
                    body: JSON.stringify(multiStore)
                }
            );

            return (await result.json()) as AddConnectifStoreResponse;
        } catch (error) {
            const response: AddConnectifStoreErrorResponse = {
                success: false,
                errorCode: error.errorCode
            };
            return response;
        }
    },

    async deleteStore(
        signed_payload: string,
        storeId: string
    ): Promise<boolean> {
        const result = await RequestService.cnFetch(
            `/api/app/multi-store/${storeId}`,
            {
                method: 'DELETE',
                bigcommerceSignature: signed_payload
            }
        );

        const response = (await result.json()) as {
            success: boolean;
        };

        if (!response.success && result.status === 404) {
            return false;
        }

        return response.success;
    }
};

export default MultiStoreService;
