import React, { ReactElement } from 'react';
import {
    Flex,
    FlexItem,
    Input,
    InputProps,
    Text
} from '@bigcommerce/big-design';
import { useTranslation } from 'react-i18next';
import { ImageSettings } from '../../models/ImageSettings';

interface Props {
    title: string;
    description: string;
    imageSettings: ImageSettings;
    onChange: (imageSettings: ImageSettings) => void;
}

export default function ImagePropertiesConfig({
    title,
    description,
    imageSettings,
    onChange
}: Props): ReactElement {
    const { t } = useTranslation();

    const handleHeightChange: InputProps['onChange'] = event => {
        const height = +event.target.value;
        const settings: ImageSettings = { width: imageSettings.width, height };
        onChange(settings);
    };

    const handleWidthChange: InputProps['onChange'] = event => {
        const width = +event.target.value;
        const settings: ImageSettings = { width, height: imageSettings.height };
        onChange(settings);
    };

    return (
        <Flex flexGap={'15px'} flexDirection={'column'}>
            <FlexItem>
                <Flex flexGap={'10px'} flexDirection={'column'}>
                    <FlexItem>
                        <span className='cn-option-title'>{title}</span>
                    </FlexItem>
                    <FlexItem>
                        <span>{description}</span>
                    </FlexItem>
                </Flex>
            </FlexItem>
            <FlexItem>
                <Flex flexDirection={'row'} flexGap={'20px'}>
                    <FlexItem>
                        <Input
                            iconRight={<Text>px</Text>}
                            label={t(
                                'CONFIGURATION.TABS.PRODUCTS.IMAGES.INPUTS.HEIGHT'
                            )}
                            type='number'
                            value={imageSettings.height}
                            onChange={handleHeightChange}
                            min={1}
                            required
                        />
                    </FlexItem>
                    <FlexItem>
                        <Input
                            iconRight={<Text>px</Text>}
                            label={t(
                                'CONFIGURATION.TABS.PRODUCTS.IMAGES.INPUTS.WIDTH'
                            )}
                            type='number'
                            value={imageSettings.width}
                            onChange={handleWidthChange}
                            min={1}
                            required
                        />
                    </FlexItem>
                </Flex>
            </FlexItem>
        </Flex>
    );
}
