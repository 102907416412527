import React, { createContext, ReactNode, useContext } from 'react';
import { useSearchParams } from 'react-router-dom';

interface SignedPayloadContextProps {
    signedPayload: string;
}

const SignedPayloadContext = createContext<
    SignedPayloadContextProps | undefined
>(undefined);

export function SignedPayloadProvider({ children }: { children: ReactNode }) {
    const [queryParameters] = useSearchParams();
    const signedPayload = queryParameters.get('signed_payload') as string;

    return (
        <SignedPayloadContext.Provider value={{ signedPayload }}>
            {children}
        </SignedPayloadContext.Provider>
    );
}

export function useSignedPayload() {
    const context = useContext(SignedPayloadContext);
    if (context === undefined) {
        throw new Error(
            'useSignedPayload must be used within an SignedPayloadProvider'
        );
    }
    return context;
}
