import React, { Fragment } from 'react';
import { Component } from 'react';
import './App.css';
import UnauthorizedPage from './pages/unauthorized/Unauthorized';
import HomePage from './pages/home/Home';
import AuthPage from './pages/auth/Auth';
import initTranslations from './i18n/config';
import JwtDecode from 'jwt-decode';
import { Route, Routes } from 'react-router';
import { BrowserRouter } from 'react-router-dom';
import { SignedPayloadProvider } from './state/SignedPayloadContext';

interface Props {}

interface State {
    isSidebarExpanded: boolean;
    showToast: boolean;
}

class App extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            isSidebarExpanded: false,
            showToast: false
        };

        const queryParameters = new URLSearchParams(location.search);
        const signedPayload = queryParameters.get('signed_payload') as string;
        let userLocale = '';
        if (signedPayload) {
            const decodedSign: { user?: { locale?: string } } = JwtDecode(
                signedPayload,
                { header: true }
            );
            userLocale = decodedSign?.user?.locale || '';
        }

        initTranslations(userLocale);
    }

    expandMenu() {
        this.setState({
            ...this.state,
            isSidebarExpanded: !this.state.isSidebarExpanded
        });
    }

    render() {
        return (
            <Fragment>
                <div className='app-body'>
                    <BrowserRouter>
                        <SignedPayloadProvider>
                            <Routes>
                                <Route
                                    path='/unauthorized'
                                    element={<UnauthorizedPage />}
                                />
                                <Route path='/load' element={<HomePage />} />
                                <Route path='/auth' element={<AuthPage />} />
                            </Routes>
                        </SignedPayloadProvider>
                    </BrowserRouter>
                </div>
            </Fragment>
        );
    }
}

export default App;
