import React, { ReactElement, useEffect, useState } from 'react';
import './StoreSettingsBox.css';
import { useTranslation } from 'react-i18next';
import {
    Badge,
    Box,
    Collapse,
    Flex,
    FlexItem,
    Switch,
    Tooltip,
    Text
} from '@bigcommerce/big-design';
import barChart from '../../assets/icons/bar_chart.svg';
import IconTitle from '../IconTitle/IconTitle';
import { ConnectifStore } from '../../models/ConnectifStore';
import { AppSettings } from '../../models/AppSettings';
import MultiStoreSettings from './MultiStoreSettings/MultiStoreSettings';
import DefaultStoreForm from './DefaultStoreForm/DefaultStoreForm';
import { StoreError } from '../../models/AppSettingsResponse';
import { CheckIcon } from '@bigcommerce/big-design-icons';

interface Props {
    appSettings: AppSettings;
    defaultConnectifStore: ConnectifStore;
    onChangeTrackingStatus: (status: boolean) => void;
    handleSwitchMultiStoreSettings: (status: boolean) => void;
    onChangeDefaultConnectifStore: (
        connectifStorePayload: ConnectifStore,
        hasErrors: boolean
    ) => void;
    multiStoreErrors: StoreError[];
}

export function StoreSettingsBox({
    appSettings,
    defaultConnectifStore,
    onChangeTrackingStatus,
    handleSwitchMultiStoreSettings,
    onChangeDefaultConnectifStore,
    multiStoreErrors
}: Props): ReactElement {
    const { t } = useTranslation();
    const [trackingEnabled, setTrackingEnabled] = useState<boolean>(
        appSettings.trackingEnabled
    );

    useEffect(() => {
        setTrackingEnabled(appSettings.trackingEnabled);
    }, [appSettings.trackingEnabled]);

    const renderMultiStoreSwitch = () => (
        <Switch
            disabled={appSettings.trackingEnabled}
            checked={!!appSettings.multiStoreEnabled}
            onChange={() =>
                handleSwitchMultiStoreSettings(!appSettings.multiStoreEnabled)
            }
        />
    );

    return (
        <Box marginTop={'large'} border='box' borderRadius='normal'>
            <Flex
                padding={'large'}
                className='cn-section-header'
                justifyContent={'space-between'}
                alignItems={'center'}
                flexGap={'15px'}
            >
                <FlexItem>
                    <Flex alignItems={'center'} flexGap={'20px'}>
                        <FlexItem className='cn-section-title'>
                            {t('STORE.HEADER.TITLE')}
                        </FlexItem>
                        <FlexItem>
                            {appSettings.trackingEnabled ? (
                                <Badge
                                    label={t('STORE.HEADER.TRACKING_ENABLED')}
                                    variant='success'
                                />
                            ) : (
                                <Badge
                                    label={t('STORE.HEADER.TRACKING_DISABLED')}
                                    variant='warning'
                                />
                            )}
                        </FlexItem>
                    </Flex>
                </FlexItem>
                <FlexItem>
                    <Flex flexDirection={'row'} flexGap={'20px'}>
                        <FlexItem>
                            <span>{t('STORE.HEADER.MULTISTORE')}</span>
                        </FlexItem>
                        <FlexItem>
                            {appSettings.trackingEnabled ? (
                                <Tooltip
                                    placement='bottom'
                                    trigger={
                                        <Box>{renderMultiStoreSwitch()}</Box>
                                    }
                                >
                                    {t('STORE.MULTISTORE.TOOLTIP_MESSAGE')}
                                </Tooltip>
                            ) : (
                                renderMultiStoreSwitch()
                            )}
                        </FlexItem>
                    </Flex>
                </FlexItem>
            </Flex>

            <Flex padding={'large'} flexDirection={'column'} flexGap={'30px'}>
                <FlexItem>
                    <Box padding='xxLarge' shadow='raised'>
                        {!appSettings.multiStoreEnabled ? (
                            <DefaultStoreForm
                                onChange={onChangeDefaultConnectifStore}
                                defaultConnectifStore={defaultConnectifStore}
                                disabled={appSettings.trackingEnabled}
                                showError={trackingEnabled}
                            />
                        ) : (
                            <MultiStoreSettings
                                appSettings={appSettings}
                                multiStoreErrors={multiStoreErrors}
                            />
                        )}
                    </Box>
                </FlexItem>
                <FlexItem>
                    <Box padding='xxLarge' shadow='raised'>
                        <IconTitle
                            iconSrc={barChart}
                            title={t('STORE.TRACKING.TITLE')}
                            description={t('STORE.TRACKING.DESCRIPTION')}
                        />
                        <Flex
                            marginTop={'large'}
                            marginBottom={'small'}
                            flexGap={'20px'}
                        >
                            <FlexItem>
                                <span>
                                    {t('STORE.TRACKING.CHECKBOX_TITLE')}
                                </span>
                            </FlexItem>
                            <FlexItem>
                                <Switch
                                    checked={trackingEnabled}
                                    onChange={newValue => {
                                        setTrackingEnabled(
                                            newValue.currentTarget.checked
                                        );
                                        onChangeTrackingStatus(
                                            newValue.currentTarget.checked
                                        );
                                    }}
                                />
                            </FlexItem>
                        </Flex>
                        <Collapse title={t('TRACKING.TITLE')}>
                            <Flex flexGap={'12px'} flexDirection={'column'}>
                                <Flex alignItems={'center'} flexGap={'10px'}>
                                    <CheckIcon color='success' size='large' />
                                    <Text>
                                        {t('TRACKING.VALIDATE_CORRECT_STORES')}
                                    </Text>
                                </Flex>
                                <Flex flexDirection={'column'}>
                                    <Flex
                                        alignItems={'center'}
                                        flexGap={'10px'}
                                    >
                                        <CheckIcon
                                            color='success'
                                            size='large'
                                        />
                                        <Text>
                                            {t(
                                                'TRACKING.ADD_SCRIPT_DESCRIPTION'
                                            )}
                                        </Text>
                                    </Flex>
                                    <ul className='cn-description-tracking-list'>
                                        <li>
                                            {t(
                                                'TRACKING.ADD_SCRIPT_CART_CONTENT'
                                            )}
                                        </li>
                                        <li>
                                            {t(
                                                'TRACKING.ADD_SCRIPT_VISITED_CATEGORIES'
                                            )}
                                        </li>
                                        <li>
                                            {t(
                                                'TRACKING.ADD_SCRIPT_CUSTOMER_INFO'
                                            )}
                                        </li>
                                        <li>
                                            {t(
                                                'TRACKING.ADD_SCRIPT_ONLINE_OFFLINE_ORDERS'
                                            )}
                                        </li>
                                        <li>
                                            {t(
                                                'TRACKING.ADD_SCRIPT_PRODUCT_INFO'
                                            )}
                                        </li>
                                        <li>
                                            {t(
                                                'TRACKING.ADD_SCRIPT_SEARCH_INFO'
                                            )}
                                        </li>
                                    </ul>
                                </Flex>

                                <Flex alignItems={'center'} flexGap={'10px'}>
                                    <CheckIcon color='success' size='large' />
                                    <Text>
                                        {t('TRACKING.ADD_SERVICE_WORKER')}
                                    </Text>
                                </Flex>
                                <Flex alignItems={'center'} flexGap={'10px'}>
                                    <CheckIcon color='success' size='large' />
                                    <Text>
                                        {t('TRACKING.ADD_WEBHOOK_ORDERS')}
                                    </Text>
                                </Flex>
                            </Flex>
                        </Collapse>
                    </Box>
                </FlexItem>
            </Flex>
        </Box>
    );
}
